.pageWrapper {
    background: #fff;
}

.tagSign {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #1B1F23;
    padding-top: 24px;
}

.tagSign span {
    font-weight: 700;
}

.introBlock {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-areas:
         'introImageWrapper introRight';
    grid-gap: 56px;
    margin-bottom: 68px;
}

.introImageWrapper {
    width: 100%;
    overflow: hidden;
    grid-area: introImageWrapper;
}

.introImageWrapper img{
    width: 100%;
}

.introRight {
    grid-area: introRight;
}

.introTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #197F58;
    margin-bottom: 24px;
}

.introText {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #1B1F23;
}

.articleText {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #1B1F23;
    margin-bottom: 36px;
}

.articleBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shareWrapper {
    display: flex;
    align-items: center;
}

.shareLabel {
    font-weight: 400;
    font-size: 16px;
    color: #5D5D5D;
}

.shareBtn {
    margin-left: 24px;
}

.bottomSign {
    display: flex;
    align-items: center;
}

.byAdmin {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #5D5D5D;
    margin-right: 20px;
}

.date {
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    color: #176E4E;
}

.customHr {
    background: #DEDEDE;
    height: 1px;
    margin-top: 56px;
}

.blogWrapper {
    padding-bottom: 50px;
}

@media only screen and (max-width: 600px ) {
    .introBlock {
        grid-template-columns: 1fr;
        grid-template-areas:
         'introRight'
        'introImageWrapper';
        margin-bottom: 36px;
    }

    .articleBottom {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .shareWrapper {
        margin-top: 24px;
    }

    .introImageWrapper {
        display: flex;
        justify-content: center;
    }

    .articleText img{
        width: 100%;
    }

}

