.headerWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;
    position: relative;
    z-index: 100;
}

.navList {
    display: flex;
    align-items: center;
}

.navListItem {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 36px;
    position: relative;
}

.active {
    color: #2FB37F;
}

.active:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #2FB37F;
    border-radius: 50%;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
}

.headerRight {
    display: flex;
    align-items: center;
}

.applyBtn {
    background: #2FB37F;
    border-radius: 20px;
    padding: 8px 21px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.mobileMenuList {
    display: none;
}

.menuToggleBtn {
    display: none;
}

.emptyMobileHeader {
    display: none;
}

.logoWrapper {
    max-width: 126px;
}

.logoWrapper img {
    width: 100%;
}


@media only screen and (max-width: 600px ) {
    .headerRight {
        display: none;
    }

    .emptyMobileHeader {
        display: block;
        width: 100%;
        height: 100px;
    }

    .headerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 999;
        overflow: hidden;
        left: 0;
        top: 0;
        transition: all 300ms ease;
    }

    .headerWrapperActive {
        height: 100vh;
        background: #1A1E26;
    }

    .mobileMenuList {
        padding-top: 30px;
        display: block;
        opacity: 0;
        transition: all 300ms ease;
    }

    .mobileMenuList li {
        padding: 0 24px;
    }

    .mobileMenuListActive {
        opacity: 1;
    }

    .menuToggleBtn {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        outline: none;
    }

    .logoWrapper {
        padding-left: 24px;
    }

    .navListItemMobile {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 14px 24px;
        display: block;
        outline: none !important;
        transition: all 300ms ease;
    }

    .activeMobile {
        background: #124D39;
    }
}
