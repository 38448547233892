.page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow: hidden;
}

.leftImage {
    height: 100vh;
}

.rightSide {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    margin-bottom: 16px;
    position: relative;
}

.title:before {
    content: '';
    position: absolute;
    width: 56px;
    height: 56px;
    background: #197F58;
    top: -50px;
    left: -70px;
}

.subTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.formWrapper {
    width: 100%;
    max-width: 550px;
}

.form {

}

.doubleInputRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #6E6E6E;
}

.input {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    color: #B3B3B3;
    padding: 12px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.input::placeholder {
    color: #B3B3B3;
}

.inputError {
    border: 1px solid rgb(191, 22, 80);
    border-radius: 2px;
}

.inputPhoneWrapper {
    background: #262B40;
    padding: 12px 16px;
}

.inputPhone {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    border: none;
    background: transparent;
}

.prefixSelect {
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
    margin-right: 10px;
}

.joinBtn {
    background: #2FB37F;
    width: 100%;
    padding: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 300ms ease;
    border: none;
}

.joinBtn:hover {
    background: #3AD397;
}

.joinBtn:active {
    background: #197F58;
}

.actionBtnList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    margin-top: 34px;
}

.backBtn {
    border: 1px solid #303752;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #DEDEDE;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    cursor: pointer;
}

.backBtn img {
    margin-right: 5px;
}

.textError {
    color: rgb(191, 22, 80) !important;
}

.select {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    color: #838383;
    padding: 11px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.select option {
    color: #B3B3B3;
}

.selectError {
    border: 1px solid rgb(191, 22, 80);
}

.termsAndConditionsWrapper {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.termsAndConditionsWrapper .text {
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 7px;
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 600px ) {
    .leftImageWrapper {
        display: none;
    }

    .page {
        padding: 20px;
    }

    .fullNameWrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 12px;
    }

    .title:before {
        display: none;
    }

    .subTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 34px;
    }

    .doubleInputRow {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

