.blogItemContainer {
    width: 100%;
}

.imageContainer {
    width: 265px;
    height: 180px;
    overflow: hidden;
}

.imageContainerImg {
    width: 100%;
}

.date {
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
}

.title {
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-height: 42px;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.redirectBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #F47D63;
    margin-top: 18px;
    display: flex;
    align-items: center;
}

.light span {
    color: #197F58;
}

.redirectBtn img {
    margin-left: 10px;
}

.light p {
    color: #1B1F23;
}

@media (max-width: 767px) {
    .imageContainer {
        width: 100%;
    }
}
