.title {
    font-weight: 400;
    font-size: 38px;
    line-height: 56px;
    text-align: center;
    text-transform: uppercase;
    color: #F47D63;
    margin-bottom: 24px;
    padding-top: 81px;
}

.faqList {
    padding-bottom: 84px;
}

.item {
    background: #212630;
    margin-bottom: 12px;
    padding: 24px 29px 28px 64px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.itemTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    transition: all 300ms ease;
}

.itemTitleOpen {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #258968;
}

.itemText {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}

.arrowWrapper {
    cursor: pointer;
    padding: 10px;
}

.arrowOpen {
    transform: rotate(180deg);
    transition: all 300ms ease;
}

.arrowClose {
    transform: rotate(0deg);
    transition: all 300ms ease;
}

@media only screen and (max-width: 600px ) {
    .item {
        padding: 24px;
    }
}
