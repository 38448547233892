.pageWrapper {
    padding: 25px 0;
}

@media only screen and (max-width: 600px) {
    .pageWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
