.juryWrapper {
    padding-bottom: 56px;
}

.firstTitle {
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #FFFFFF;
    padding-top: 72px;
}

.secondTitle {
    font-weight: 700;
    font-size: 56px;
    line-height: 54px;
    color: #FFFFFF;
    margin-bottom: 76px;
}

.juryList {
    position: relative;
}

.juryName {
    font-weight: 700;
    font-size: 15px;
    margin: 5px 0;
    color: #FFFFFF;
    max-width: 180px;
}

.juryPosition {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    max-width: 206px;
}

.juryImageWrapper {
    width: 173px;
    height: 156px;
    z-index: 5;
    overflow: hidden;
}

.juryImageWrapper img {
    width: 100%;
}

.juryItem {
    position: relative;
    z-index: 10;
    max-width: 173px;
}

.juryItemWrapper {
    width: 100%;
    display: flex !important;
    justify-content: center;
}

.square {
    width: 100px;
    height: 100px;
    z-index: -5;
    position: absolute;
}

.topLeft {
    top: -40px;
    left: -40px;
}

.bottomLeft {
    bottom: 20px;
    left: -40px;
}

.topRight {
    top: -40px;
    right: 70px;
}

.bottomRight {
    bottom: 40px;
    right: 60px;
}

.comingSoon {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding-bottom: 35px;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -10px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}


@media only screen and (max-width: 600px ) {
    .juryItem {
        margin-right: 25px;
    }

    .square {
        display: none;
    }

    .nav {
        display: none;
    }
}
