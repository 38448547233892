.intro {
    height: 280px;
    margin-bottom: 48px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.introTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding-top: 24px;
}

.content {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 75px 3fr;
    grid-gap: 20px;
    grid-template-areas:
    "facts figure article"
    "team team team"
    "stFoImg stFoImg stFoImg";
    padding-top: 95px;
    padding-bottom: 60px;
}

.facts {
    grid-area: facts;
}

.figureWrapper {
    grid-area: figure;
    margin-top: -50px;
}

.figureWrapperImg {
    background-repeat: no-repeat;
    width: 75px;
    height: 84px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}

.article {
    grid-area: article;
}

.teamWrapper {
    grid-area: team;
    margin-bottom: 36px;
}

.startupFooterImg {
    grid-area: stFoImg;
}

.startupFooterImg img {
    width: 100%;
}

.factsTitle {
    font-weight: 700;
    font-size: 18px;
    color: #F47D63;
    margin-bottom: 36px;
}

.factsList {
    margin-left: 11px;
}

.factsItem {
    display: grid;
    grid-template-columns: 33px 1.7fr 6fr;
    margin-bottom: 26px;
    align-items: center;
    grid-gap: 10px;
}

.factLabel {
    font-weight: 400;
    font-size: 12px;
    color: #DEDEDE;
}

.factText {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #197F58;
}

.articleTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.articleSubtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.articleTextTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 6px;
    cursor: pointer;
    padding: 6px 21px;
    display: inline-block;
    background: #2FB37F;
    border-radius: 10px;
}

.articleText {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
}

.teamTitle {
    color: #F47D63;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
}

.teamNames {
    font-size: 16px;
    line-height: 28px;
    color: #DEDEDE;
    max-width: 334px;
}

.teamUrl {
    font-size: 16px;
    line-height: 28px;
    color: #DEDEDE;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .content {
        grid-template-columns: 1fr;
        grid-template-areas:
        "figure"
        "article"
        "stFoImg"
        "facts"
        "team";
        padding-bottom: 0;
        padding-top: 20px;
    }
}
