.juryWrapper {
    padding-bottom: 56px;
    overflow: hidden;
}

.firstTitle {
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #FFFFFF;
    padding-top: 72px;
}

.secondTitle {
    font-weight: 700;
    font-size: 56px;
    line-height: 54px;
    color: #FFFFFF;
    margin-bottom: 76px;
}

.juryList {
    position: relative;
}

.juryName {
    font-weight: 700;
    font-size: 15px;
    margin: 5px 0;
    color: #FFFFFF;
    max-width: 180px;
}

.juryPosition {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    max-width: 206px;
}

.juryImageWrapper {
    width: 173px;
    height: 156px;
    z-index: 5;
    overflow: hidden;
}

.juryImageWrapper img {
    width: 100%;
}

.juryItem {
    position: relative;
    z-index: 10;
    max-width: 173px;
}

.juryItemWrapper {
    width: 100%;
    display: flex !important;
    justify-content: center;
}

.square {
    width: 100px;
    height: 100px;
    z-index: -5;
    position: absolute;
}

.topLeft {
    top: -40px;
    left: -40px;
}

.bottomLeft {
    bottom: 20px;
    left: -40px;
}

.topRight {
    top: -40px;
    right: 70px;
}

.bottomRight {
    bottom: 40px;
    right: 60px;
}

.comingSoon {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding-bottom: 35px;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -10px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.juryListGrid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}


.speakerContainer {
    width: 100%;
    border-bottom: 1px solid rgba(125, 124, 124, 0.7);
    border-right: 1px solid rgba(125, 124, 124, 0.7);
    position: relative;
    padding: 30px 25px;
}

.speakerContainer:nth-child(1),
.speakerContainer:nth-child(2),
.speakerContainer:nth-child(3),
.speakerContainer:nth-child(4),
.speakerContainer:nth-child(5),
.speakerContainer:nth-child(6) {
    border-top: 1px solid rgba(125, 124, 124, 0.7);
}

.speakerContainer:nth-child(6n) {
    border-right: none;
}

.speakerContainer:nth-child(1):before {
    position: absolute;
    top: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: rgba(125, 124, 124, 0.7);
    left: -1000px;
}

.speakerContainer:nth-child(6):before {
    position: absolute;
    top: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: rgba(125, 124, 124, 0.7);
    right: -1000px;
}

.speakerContainer:nth-child(6n):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: rgba(125, 124, 124, 0.7);
    right: -1000px;
}

.speakerContainer:nth-child(6n + 1):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: rgba(125, 124, 124, 0.7);
    left: -1000px;
}


.speakerContainer:nth-child(6n + 1):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: rgba(125, 124, 124, 0.7);
    left: -1000px;
}

.speakerContainerImage {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.speakerContainerImageWrapper {
    width: 140px;
    height: 120px;
    overflow: hidden;
}

.speakerContainerName {
    font-weight: 700;
    font-size: 15px;
    margin-top: 16px;
    color: #FFFFFF;
}

.speakerContainerPost {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    margin-top: 10px;
    color: #FFFFFF;
}

.speakerList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.comingSoon {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding-bottom: 35px;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

@media only screen and (max-width: 1350px ) {
    .juryListGrid{
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (max-width: 1024px ) {
    .juryListGrid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px ) {
    .juryItem {
        margin-right: 25px;
    }

    .square {
        display: none;
    }

    .nav {
        display: none;
    }

    .speakerContainer:before, .speakerContainer:after {
        content: none;
    }
}
