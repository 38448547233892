.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 41px;
    color: #FFFFFF;
    padding-top: 48px;
    padding-bottom: 24px;
}

.blogsItemList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding-bottom: 100px;
}

.light {
    color: #1B1F23;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

.item {
    padding: 0 10px;
}

.thisWidth {
    max-width: 1160px;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -20px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 600px ) {
    .blogsItemList {
        grid-template-columns: 1fr;
    }

    .thisWidth {
        max-width: 1160px;
        width: 100%;
        display: block;
        margin: 0 auto;
        padding: 0 0 0 20px;
    }

    .nav {
        display: none;
    }
}
