.intro {
    width: 100%;
    max-width: 693px;
    margin: 0 auto;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-top: 100px;
    margin-bottom: 12px;
    position: relative;
}

.title:before {
    position: absolute;
    content: '';
    width: 36px;
    height: 36px;
    left: -38px;
    top: -35px;
    background: #176E4E;
}

.subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #F47D63;
    margin-bottom: 18px;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.greenText {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2FB37F;
    margin-bottom: 18px;
}

.list {
    margin-bottom: 48px;
    list-style: none;
    line-height: 32px;
}

.list li {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}

.list li a {
    color: #67BCF9;
}

.doubleInput {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.formSection {
    width: 100%;
    background: #1f242e;
    padding-bottom: 120px;
}

.formSectionTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    padding: 38px 0;
}

.form {
    border: 1px solid #303752;
    max-width: 930px;
    margin: 0 auto;
    padding: 36px 95px;
}

.formTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #F47D63;
    margin-bottom: 16px;
}

.teamTitle {
    margin-top: 36px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.teamMemberItem{
    margin-bottom: 10px;
}

.teamMemberItem + .teamMemberItem{
    border-top: 1px solid #303752;
    padding-top: 25px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
}

.input {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    padding: 12px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.inputError {
    border: 1px solid rgb(191, 22, 80);
    border-radius: 2px;
}

.select {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    padding: 15px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.textError {
    color: rgb(191, 22, 80) !important;
}

.selectError {
    border: 1px solid rgb(191, 22, 80);
}

.option {
    padding: 12px 16px;
    color: #b4b4b4;
}

.option:disabled {
    color: #838383;
}

.inputPhone {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    border: none;
    background: transparent;
}

.joinBtn {
    background: #2FB37F;
    width: 100%;
    padding: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    margin: 34px auto 0;
    display: block;
    max-width: 358px;
    transition: all 300ms ease;
}

.joinBtn:hover {
    background: #3AD397;
}

.joinBtn:active {
    background: #197F58;
}

.radioList {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.radioList label {
    margin-right: 76px;
}

.addTeamMember {
    padding: 6px 149px;
    border: 2px solid #262B40;
    display: flex;
    align-items: center;
    background: transparent;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    cursor: pointer;
    margin: 0 auto 24px;
}

.addTeamMember img {
    margin-right: 11px;
}

@media only screen and (max-width: 600px) {

    .title {
        margin-top: 35px;
    }

    .title:before {
        display: none;
    }

    .formSectionTitle {
        padding: 36px 0;
    }

    .form {
        padding: 24px;
    }

    .doubleInput {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .radioList {
        flex-direction: column;
        align-items: flex-start;
    }

    .formSection {
        padding-bottom: 64px;
    }

}
