.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.card {
    max-width: 740px;
    max-height: 540px;
    width: 95vw;
    height: 80vh;
    background: #2C3743;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.like {
    width: 150px;
    transform: rotate(20deg);
    padding-bottom: 70px;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
}

.closeBtn {
    position: absolute;
    top: 45px;
    right: 45px;
}
