.titleContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 65px;
    padding-top: 120px;
    padding-bottom: 44px;
}

.subtitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    color: #FFFFFF;
    margin-bottom: 12px;
    font-style: italic;
}

.title {
    font-weight: 700;
    font-size: 44px;
    line-height: 53px;
    color: #FFFFFF;
}

.stepList {
    margin-bottom: 120px;
}

.stepTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 18px;
}

.stepText {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}

.stepImg {
    height: 100%;
}

.stepImgWrapper {
    width: 100%;
    overflow: hidden;
    grid-area: stepImgWrapper;
}

.steContent {
    padding: 64px;
    grid-area: stepContent;
}

.stepListItem {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    margin-bottom: 20px;
    grid-template-areas:
            'stepContent stepImgWrapper';
}

.stepListItem:nth-child(even) {
    grid-template-areas:
            'stepImgWrapper stepContent';
    grid-template-columns: 1.4fr 1fr;
}


@media only screen and (max-width: 600px ) {
    .titleContainerImg {
        display: none;
    }

    .stepListItem {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }

    .titleContainer {
        padding-top: 86px;
        grid-template-columns: 1fr;
    }

    .subtitle {
        font-weight: 400;
        font-size: 28px;
        line-height: 33px;
    }

    .title {
        font-weight: 700;
        font-size: 44px;
        line-height: 53px;
    }

    .steContent {
        padding: 40px;
    }

    .stepImgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
