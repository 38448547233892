.titleFirst {
    font-style: italic;
    font-weight: 400;
    font-size: 36px;
    color: #FFFFFF;
    padding-top: 64px;
}

.titleSecond {
    font-style: italic;
    font-weight: 400;
    font-size: 56px;
    color: #FFFFFF;
    position: relative;
    padding-bottom: 48px;
}

.titleSecond:after {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    background: #F47D63;
    margin-left: 8px;
    top: -10px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #262B40;
    border-top: 1px solid #fff;
}

.speakerContainer {
    width: 100%;
    border-bottom: 1px solid #262B40;
    border-right: 1px solid #262B40;
    position: relative;
    padding: 30px;
}

.speakerContainer:nth-child(1),
.speakerContainer:nth-child(2),
.speakerContainer:nth-child(3),
.speakerContainer:nth-child(4),
.speakerContainer:nth-child(5),
.speakerContainer:nth-child(6) {
    border-top: 1px solid #262B40;
}

.speakerContainer:nth-child(6n) {
    border-right: none;
}

.speakerContainer:nth-child(1):before {
    position: absolute;
    top: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: #262B40;
    left: -1000px;
}

.speakerContainer:nth-child(6):before {
    position: absolute;
    top: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: #262B40;
    right: -1000px;
}

.speakerContainer:nth-child(6n):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: #262B40;
    right: -1000px;
}

.speakerContainer:nth-child(6n + 1):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: #262B40;
    left: -1000px;
}


.speakerContainer:nth-child(6n + 1):after {
    position: absolute;
    bottom: -1px;
    width: 1000px;
    content: '';
    height: 1px;
    background: #262B40;
    left: -1000px;
}

.speakerContainerImage {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.speakerContainerImageWrapper {
    width: 140px;
    height: 120px;
    overflow: hidden;
}

.speakerContainerName {
    font-weight: 700;
    font-size: 15px;
    margin-top: 16px;
    color: #FFFFFF;
}

.speakerContainerPost {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    margin-top: 10px;
    color: #FFFFFF;
}

.speakerListGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.comingSoon {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding-bottom: 35px;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -10px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.thisPage {
    width: 100%;
    overflow: hidden;
}


.leadItemJoin {
    padding: 30px;
}

.joinUsTitle {
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.joinUsText {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    max-width: 265px;
    margin-bottom: 46px;
}

.joinUsBtn {
    background: transparent;
    border: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.joinUsBtn span {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 16px;
}

@media only screen and (max-width: 1350px ) {
    .speakerListGrid{
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (max-width: 1024px ) {
    .speakerListGrid{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px ) {

    .speakerList {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .speakerContainerName {
        line-height: normal;
    }

    .titleSecond {
        font-size: 46px;
    }

    .nav {
        display: none;
    }

    .titleFirst {
        font-size: 32px;
    }

    .juryList {
        grid-template-columns: 1fr 1fr;
    }

    .speakerContainerImageWrapper {
        width: 100%;
    }

    .speakerContainer:nth-child(1),
    .speakerContainer:nth-child(2),
    .speakerContainer:nth-child(3),
    .speakerContainer,
    .speakerContainer:nth-child(6),
    .speakerContainer:nth-child(1):before,
    .speakerContainer:nth-child(6):before,
    .speakerContainer:nth-child(6n):after,
    .speakerContainer:nth-child(6n + 1):after,
    .speakerContainer:nth-child(6n + 1):after {
        content: none;
        border: none;
    }

    .speakerContainer {
        padding: 35px 24px;
        border-top: 1px solid #262B40 !important;
        border-right: 1px solid #262B40 !important;
    }

    .thisPageWrapper {
        padding: 0;
    }

    .titleFirst {
        padding: 56px 20px 0;
    }

    .titleSecond {
        padding: 0 20px;
        margin-bottom: 42px;
    }

    .leadItemJoin {
        padding: 24px 16px;
    }
}




















