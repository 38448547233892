.introductionWrapper {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    padding-top: 110px;
    padding-bottom: 96px;
}

.introductionImageWrapper {
    width: 100%;
}

.introductionImageWrapper .bgImage {
    width: 100%;
    height: 630px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.introductionImage {
    width: 100%;
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #2FB37F;
    margin-bottom: 24px;
}

.subTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #F47D63;
    margin-bottom: 8px;
}

.text {
    font-weight: 700;
    font-size: 80px;
    line-height: 100px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.timerWrapper {
    display: grid;
    grid-template-columns: repeat(4, 140px);
    grid-gap: 20px;
}

.timerItem + .timerItem:before {
    content: ':';
    font-weight: 300;
    font-size: 64px;
    color: #DEDEDE;
    position: absolute;
    top: 10px;
    left: -15px;
}

.timerItem:after {
    content: '';
    position: absolute;
    background: #262B40;
    width: 100%;
    height: 80px;
    bottom: 0;
    z-index: 1;
}

.timerItem {
    max-width: 140px;
    position: relative;
    z-index: 10;
}

.timerNumber {
    font-weight: 700;
    font-size: 64px;
    line-height: 100px;
    text-transform: uppercase;
    color: #DEDEDE;
    text-align: center;
    width: 100%;
    z-index: 10;
    position: relative;
}

.timerDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #DEDEDE;
    text-align: center;
    z-index: 10;
    position: relative;
    margin-top: -10px;
    padding-bottom: 10px;
}

.contentWrapper {
    position: relative;
    z-index: 10;
}


@media only screen and (max-width: 600px ) {
    .introductionWrapper {
        grid-template-columns: 1fr;
        padding-bottom: 76px;
        padding-top: 10px;
    }

    .introductionImageWrapper .thisParallax {
        display: none;
    }

    .introductionImageWrapper .bgImage {
        height: 570px;
    }

    .text {
        font-size: 58px;
    }

    .timerWrapper {
        display: grid;
        grid-template-columns: repeat(4, 70px);
        grid-gap: 20px;
    }

    .timerNumber {
        font-size: 44px;
        line-height: 50px;
    }

    .timerItem:after {
        height: 60px;
    }

    .timerItem + .timerItem:before {
        font-size: 44px;
        top: -3px;
    }
}


