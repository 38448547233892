body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #1A1E26;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    outline: none;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-column {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.align-items-center {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.align-items-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.align-items-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
}

ul {
    list-style: none;
}

button {
    outline: none;
}

input {
    outline: none;
}

.btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.width {
    max-width: 1160px;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 20px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkboxLabel .mask {
    border: 2px solid #1F806E;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkboxLabel .mask img {
    transition: all 100ms ease;
    opacity: 0;
}

.checkboxLabel input:checked + .mask img {
    opacity: 1;
    width: 18px;
}

.label-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.label-radio input {
    display: none;
}


.label-radio span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #E0EBF1;
    position: relative;
    transition: all 300ms ease;
}

.label-radio input:checked + span {
    border: 1px solid #2FB37F;
}

.label-radio input:checked + span:after {
    content: '';
    background: transparent;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 300ms ease;
}

.label-radio input:checked + span:after {
    background: #2FB37F;
}

.label-radio p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
    margin-left: 8px;
}

.owl-dots {
    display: none;
}


.owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
}

.owl-nav button span {
    font-size: 60px;
    color: #fff;
}

.owl-prev span {
    font-size: 25px;
    margin-left: -50px;
}

.owl-next span {
    margin-right: -50px;
}


.onlyWeb {
    display: block;
}

.onlyMobile {
    display: none;
}

@media only screen and (max-width: 600px ) {
    .owl-nav {
        display: none;
    }

    .onlyWeb {
        display: none;
    }

    .onlyMobile {
        display: block;
    }

    .slick-slide {
        padding-right: 15px;
    }

    .eventSpeakers .slick-slide {
        padding-right: 0 !important;
    }
}












