.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    margin-bottom: 36px;
    padding-top: 86px;
}

.carouselWrapper {
    margin-bottom: 96px;
    position: relative;
}

.item {
    max-width: 265px;
    height: 226px;
    margin-right: 20px;
}

.itemInner {
    padding: 137px 24px 30px;
    display: grid;
    grid-template-columns: 1fr 48px;
    grid-gap: 15px;
}

.year {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.itemTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.arrowWrapper {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrowWrapper img {
    width: 24px !important;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -10px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

@media only screen and (max-width: 600px ) {
    .thisWidth {
        padding-right: 0;
    }

    .carouselWrapper {
        padding-bottom: 50px;
    }

    .item {
        max-width: unset;
    }

    .nav {
        display: none;
    }
}
