.paginatorWrapper{
    display: flex;
    align-items: center;
}

.paginatorItem {
    width: 44px;
    height: 50px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .7;
}

.active {
    opacity: 1;
}

.count {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}
