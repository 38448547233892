.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    padding-top: 81px;
    padding-bottom: 48px;
}

.redirectBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #F47D63;
    margin-top: 16px;
}

.redirectBtn img {
    margin-left: 10px;
}

.item {
    display: grid;
    grid-template-columns: 170px auto;
    grid-gap: 20px;
    background: #212630;
    margin-bottom: 20px;
}

.imageWrapper {
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWrapper img {
    max-width: 100%;
    max-height: 100%;
}

.startupsList {
    margin-bottom: 20px;
    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}

.paginatorWrapper{
    margin-bottom: 96px;
    display: flex;
    justify-content: flex-end;
}

.startupContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.startupTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-top: 20px;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 12px;
    max-width: 349px;
}

.itemRightSide {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    margin-top: 38px;
}

.figure {
    width: 54px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.type {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #2FB37F;
}

.place {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
}

.location {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #DEDEDE;
}

@media only screen and (max-width: 600px) {
    .title {
        padding-top: 30px;
    }

    .item {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .imageWrapper {
        overflow: hidden;
        width: 100%;
        height: auto;
    }

    .imageWrapper img {
        width: 100%;
    }

    .startupContent {
        grid-template-columns: 1fr;
        padding: 24px;
        grid-gap: 0;
    }

    .startupTitle {
        margin-top: 0;
    }

    .itemRightSide {
        grid-gap: 10px;
        grid-template-columns: 1fr 2fr 1fr;
    }
}
