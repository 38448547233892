.title {
    font-weight: 700;
    font-size: 76px;
    line-height: 91px;
    color: #FFFFFF;
    width: 100%;
    max-width: 700px;
    margin-bottom: 52px;
    position: relative;
    white-space: nowrap;
}

.title:before {
    background: #477EB5;
    width: 56px;
    height: 56px;
    position: absolute;
    content: '';
    left: -90px;
    top: 17px;
}

.subtitle {
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    width: 100%;
    max-width: 850px;
}

.contentWrapper {
    margin: 0 auto;
    display: inline-block;
    padding-top: 200px;
    padding-bottom: 175px;
}

.page {
    display: flex;
    justify-content: center;
    position: relative;
}

.imageBottom {
    position: absolute;
    bottom: 50px;
    right: 30px;
}

.imageTop {
    position: absolute;
    right: 300px;
    top: 100px;
}

.squareOrange {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 36px;
    height: 36px;
    background: #F47D63;
}

.squarePurple {
    position: absolute;
    top: 150px;
    left: 250px;
    width: 160px;
    height: 160px;
    background: #752AD6;
}

.itemList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 20px;
}

.listItem {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.pageWrapper {
    width: 100%;
    position: relative;
}

.myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.aboutText {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-left: 40px;
    position: relative;
    padding-bottom: 64px;
}

.aboutText:before {
    content: "";
    width: 36px;
    height: 36px;
    position: absolute;
    background: #F47D63;
    margin-left: -50px;
    top: 7px;
}

@media only screen and (max-width: 600px ) {
    .contentWrapper {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .title {
        font-size: 45px;
        line-height: 1;
        margin-bottom: 15px;
    }

    .subtitle {
        font-size: 26px;
        line-height: 1.2;
        position: relative;
        z-index: 100;
    }

    .squareOrange {
        display: none;
    }

    .squarePurple {
        display: none;
    }

    .imageBottom {
        z-index: 0;
    }

    .itemList {
        grid-template-columns: 1fr;
        margin-top: 15px;
    }

    .listItem {
        margin-bottom: 5px;
    }

    .aboutText {
        margin-left: 0;
    }

    .aboutText:before {
        content: none;
    }
}
