.wrapper {
    background: #1F242E;
    padding-bottom: 22px;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    padding-top: 36px;
    margin-bottom: 36px;
    text-align: center;
}

.competitionList {
    width: 100%;
}

.competitionListItem {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 30px;
    padding: 10px 95px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.competitionListItem:nth-child(odd) {
    background: #262B40;
}

.download {
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    border: 1px solid #303752;
    padding: 11px 29px;
    border-radius: 4px;
    color: #DEDEDE;
    display: flex;
    align-items: center;
}

.download img {
    margin-right: 10px;
}

.wrapperBottom {
    display: grid;
    margin-top: 24px;
    align-items: center;
}

@media only screen and (max-width: 600px ) {
    .wrapper {
        padding-bottom: 32px;
    }

    .competitionListItem {
        padding: 10px 20px;
        font-weight: 400;
        grid-template-columns: 1fr 1fr;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        align-items: center;
    }

    .competitionList {
        margin-bottom: 36px;
    }

    .thisWidth {
        padding: 0;
    }

    .wrapperBottom {
        margin-top: 0;
    }

    .download {
        margin: 0 auto;
    }
}
