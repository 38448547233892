.Dropzone {
    height: 131px;
    width: 100%;
    background: #262B40;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.Highlight {
    background-color: rgb(188, 185, 236);
}

.Icon {
}

.FileInput {
    display: none;
}

.downloadText {
    font-size: 14px;
    line-height: 34px;
    color: #C4C4C4;
}

.downloadText span {
    color: #67BCF9;
    cursor: pointer;
}
