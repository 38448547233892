.wrapper {
    background: #1F242E;
    padding-top: 56px;
    padding-bottom: 64px;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 56px;
}

.competitionList {
    width: 100%;
}

.competitionListItem {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 10px;
    padding: 10px 95px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.date {
    cursor: pointer;
}

.competitionListItem:nth-child(odd) {
    background: #262B40;
}

@media only screen and (max-width: 600px ) {
    .competitionListItem {
        padding: 10px 24px;
        font-weight: 400;
        grid-template-columns: 1fr 1fr;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        align-items: center;
    }

    .competitionList {
        margin-bottom: 56px;
    }

    .thisWidth {
        width: 100%;
        padding: 0;
    }

    .title {
        margin-bottom: 20px;
    }

    .wrapper {
        padding-bottom: 1px;
    }
}
