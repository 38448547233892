.footerWrapper {
    width: 100%;
    background: #262B40;
    padding-top: 24px;
    padding-bottom: 62px;
}

.footerFirstPart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #303752;
    padding-bottom: 26px;
}

.navList {
    display: flex;
    align-items: center;
}

.footerLogo {
    width: 164px;
}

.navListItem {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-left: 30px;
}


.footerSecondPart {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 90px;
    align-items: flex-end;
    padding-top: 28px;
}

.sign {
    font-weight: 400;
    font-size: 12px;
    color: #DEDEDE;
    margin-right: 16px;
}

.sign + .sign {
    margin-top: 27px;
}

.sign a {
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
}

.footerSecondPartItemLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.smList a {
    margin-right: 30px;
}

.inputWrapper {
    border: 1px solid #176E4E;
    border-radius: 70px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.inputWrapperInput {
    padding: 10px 16px;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #DEDEDE;
}

.inputWrapperInput::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #DEDEDE;
}

.inputWrapperBtn {
    border-radius: 0 60px 60px 0;
    background: #176E4E;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.bottomSign {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #DEDEDE;
    margin-top: 24px;
    text-align: center;
}

@media only screen and (max-width: 600px ) {
    .footerFirstPart {
        flex-direction: column;
        align-items: flex-start;
    }

    .navList {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-gap: 30px;
        width: 100%;
        margin-top: 27px;
    }

    .navListItem {
        margin-left: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
    }

    .signItems {
        grid-area: input;
        justify-content: space-between;
    }

    .sign {
        margin: 0;
    }

    .sign + .sign {
        margin-top: 10px;
    }

    .getInTouch {
        grid-area: getInTouch;
        width: 100%;
    }

    .socialMedia {
        grid-area: socialMedia;
        width: 100%;
    }

    .footerSecondPart {
        grid-template-columns: 1fr;
        grid-template-areas:
            'getInTouch'
            'socialMedia'
            'input';
        align-items: flex-start;
        grid-gap: 32px;
    }

    .bottomSign {
        text-align: left;
        margin-top: 20px;
    }
}











