.juryList {
    position: relative;
    margin-bottom: 76px;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    padding-top: 100px;
    padding-bottom: 60px;
}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.prevBtn {
    margin-left: -30px;
}

.nextBtn {
    margin-right: -10px;
}

.navBtn {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.sliderItemWrapper {
    height: 210px;
    width: 170px;
}

.sliderItem {
    background: #1F242E;
    height: 210px;
    width: 170px;
}

.imageWrapper {
    width: 100%;
    height: 134px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imageWrapper img {
    max-width: 100%;
}

.name {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    max-width: 150px;
    margin: 20px auto 26px;
}


@media only screen and (max-width: 600px ) {

    .square {
        display: none;
    }

    .nav {
        display: none;
    }

}
