.mainWrapper {
    background: #258968;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    padding-top: 56px;
    margin-bottom: 56px;
    position: relative;
}

.titleBefore {
    width: 36px;
    height: 36px;
    background: #7EC8A7;
    margin-right: 16px;
}

.competitionList {
    width: 100%;
    padding-bottom: 110px;
}

.competitionListItem {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-gap: 10px;
    padding: 10px 95px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.competitionListItem:nth-child(odd) {
    background: #228061;
}

.date {
    white-space: pre-wrap;
    line-height: 28px;
}

.download {
    margin-left: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    border: 1px solid #DEDEDE;
    padding: 11px 29px;
    border-radius: 4px;
    color: #DEDEDE;
    display: flex;
    align-items: center;
}

.download img {
    margin-right: 10px;
}

.wrapperBottom {
    display: grid;
    margin-top: 24px;
    padding-bottom: 24px;
    align-items: center;
}
@media only screen and (max-width: 600px ) {
    .competitionListItem {
        padding: 10px 24px;
        font-weight: 400;
        grid-template-columns: 1fr 1fr;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        align-items: center;
    }

    .competitionList {
        padding-bottom: 36px;
    }

}
