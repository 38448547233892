.intro {
    display: grid;
    grid-template-columns: 287px 1fr;
    padding-top: 81px;
}

.introTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.introSubtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #F47D63;
    margin-bottom: 18px;
}

.introText {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #DEDEDE;
    margin-bottom: 32px;
    max-width: 706px;
}

.introGreenText {
    color: #2FB37F;
    max-width: 613px;
}

.partnerList {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    margin-bottom: 96px;
    margin-top: 20px;
}

.partnerListItem {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1F242E;
}

.partnerList img{
    width: 100px;
}

.partnerItem {
    width: 100%;
}

.become {
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-gap: 15px;
    padding-bottom: 48px;
}

.becomeFigureWrapper img {
    display: block;
    margin: 0 auto;
}

.becomeTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.becomeSubtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #F47D63;
    margin-bottom: 18px;
    max-width: 480px;
}

.becomeText {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #DEDEDE;
    max-width: 740px;
}

.formSection {
    background: #1F242E;
}

.formWrapper {
    max-width: 550px;
    margin: 0 auto;
    padding: 36px 0 48px;
}

.doubleInputWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
}

.input {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    padding: 12px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.inputError {
    border: 1px solid rgb(191, 22, 80);
    border-radius: 2px;
}

.inputPhoneWrapper {
    background: #262B40;
    padding: 12px 16px;
}

.select {
    background: #262B40;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    padding: 15px 16px;
    box-sizing: border-box;
    transition: border 200ms ease;
    border: 1px solid transparent;
}

.termsAndConditionsWrapper {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.termsAndConditionsWrapper .text {
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left: 7px;
    cursor: pointer;
    text-decoration: underline;
}

.inputPhone {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #838383;
    border: none;
    background: transparent;
}

.prefixSelect {
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #DEDEDE;
    margin-right: 10px;
}

.joinBtn {
    background: #2FB37F;
    width: 100%;
    padding: 14px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    border: none;
    margin-top: 34px;
    transition: all 300ms ease;
}

.joinBtn:hover {
    background: #3AD397;
}

.joinBtn:active {
    background: #197F58;
}

.textError {
    color: rgb(191, 22, 80) !important;
}

@media only screen and (max-width: 600px) {
    .intro {
        padding-top: 30px;
        grid-template-columns: 1fr;
    }

    .figureWrapper {
        display: none;
    }

    .partnerList {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 64px;
    }

    .become {
        grid-template-columns: 1fr;
    }

    .becomeFigureWrapper {
        display: none;
    }

    .doubleInputWrapper {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }

    .formWrapper {
        padding: 24px 20px;
    }

    .join {
        margin-top: 40px;
    }

}
