.tag {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    padding-top: 42px;
}

.tag span {
    font-size: 18px;
}

.wrapper {
    padding-top: 64px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
}

.date {
    margin-top: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #2FB37F;
}

.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    width: 480px;
    color: #F47D63;
    margin-top: 16px;
}

.subtitle {
    margin-top: 16px;
    font-weight: 700;
    font-size: 100px;
    line-height: 100px;
    color: #FFFFFF;
    margin-bottom: 118px;
}

@media only screen and (max-width: 600px ) {
    .imageWrapper {
        display: none;
    }

    .tag {
        padding-top: 15px;
    }

    .date {
        margin-top: 64px;
    }

    .wrapper {
        padding-top: 0;
        grid-template-columns: 1fr;
    }

    .title {
        max-width: 420px;
        width: 100%;
    }

    .subtitle {
        font-weight: 700;
        font-size: 65px;
        line-height: 80px;
        margin-bottom: 64px;
    }
}
