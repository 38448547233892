.note {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding-left: 95px;
    position: relative;
    max-width: 700px;
    margin-top: 70px;
}

.note:before {
    content: '';
    width: 36px;
    height: 36px;
    background: #F47D63;
    position: absolute;
    margin-left: -50px;
}

.countryTitle {
    padding-top: 96px;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.countryList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 96px;
}

.countryItem {
    padding: 21px 24px;
    border: 1px solid #262B40;
    display: grid;
    grid-template-columns: 66px 1fr;
    grid-gap: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.countryItem img {
    width: 39px;
}

.countryItem:hover {
    background: #212630;
}

.countryName {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.leadIntro {
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-gap: 15px;
    margin-bottom: 64px;
}

.leadTitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.leadSubtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #F47D63;
    margin-bottom: 18px;
}

.leadText {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #DEDEDE;
}

.leadList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 86px;
}

.leadItem {
    background: #1F242E;
    padding: 24px 36px 58px;
}

.itemTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 36px;
}

.itemContent {
    display: flex;
}

.itemImgWrapper {
    width: 170px;
    height: 164px;
    margin-right: 20px;
    overflow: hidden;
}

.itemImgWrapper img {
    width: 100%
}

.itemName {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #F47D63;
    margin-bottom: 8px;
    max-width: 160px;
}

.itemJob {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.smWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.smLabel {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
    margin-right: 16px;
}

.smList {
    display: flex;
    align-items: center;
}

.smList a {
    margin-right: 20px;
    cursor: pointer;
}

.itemPhone {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
    margin-bottom: 8px;
}

.itemEmail {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
}

.leadItemJoin {
    padding: 64px 56px;
    border: 2px solid #262B40;
}

.joinUsTitle {
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.joinUsText {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    max-width: 265px;
    margin-bottom: 46px;
}

.joinUsBtn {
    background: transparent;
    border: none;
}

.joinUsBtn span {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 16px;
}

.modal {
    position: absolute;
    transition: all 300ms ease;
    padding: 43px 40px;
    background: #FFFFFF;
    width: 265px;
    z-index: 99;
    margin-top: 100%;
    border-radius: 15px;
}

.closeModalBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
}

.closeModalBtn img {
    width: 100%;
}

.modalCountryName {
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #2FB37F;
    margin-bottom: 10px;
}

.countryInfo {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1B1F23;
}

.notActive {
    color: #ABABAB;
}

.redirectBtn {
    line-height: 28px;
    margin-top: 18px;
    display: flex;
    align-items: center;
}

.redirectBtn span {
    color: #1B1F23;
    font-weight: 700;
    font-size: 16px;
}

.redirectBtn img {
    margin-left: 10px;
    width: 25px;
}

@media only screen and (max-width: 600px ) {
    .note {
        padding-left: 50px;
    }

    .countryTitle {
        padding-top: 60px;
    }

    .countryItem {
        grid-template-columns: 50px 1fr;
    }

    .countryList {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 64px;
    }

    .leadIntro {
        grid-template-columns: 1fr;
        margin-bottom: 36px;
    }

    .leadLeft {
        display: none;
    }

    .leadList {
        grid-template-columns: 1fr;
    }

    .thisWidth {
        padding: 0;
    }

    .smWrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .itemImgWrapper {
        width: 120px;
        height: 120px;
    }

    .countryItem {
        padding: 17px 17px;
    }

    .modal {
        width: 100%;
        left: 0;
        position: fixed;
        z-index: 99;
        bottom: 0;
        border-radius: 0;
    }
}












