.leadWrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    padding-top: 64px;
    padding-bottom: 96px;
}

.itemContent {
    display: flex;
}

.person {
    padding: 24px 95px 58px;
    background: #1F242E;
}

.itemImgWrapper {
    width: 170px;
    height: 164px;
    margin-right: 20px;
    overflow: hidden;
}

.itemTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
    padding-bottom: 36px;
}

.itemImgWrapper img {
    width: 100%
}

.itemName {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #F47D63;
    margin-bottom: 8px;
}

.itemJob {
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.smWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.smLabel {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
    margin-right: 16px;
}

.smList a {
    margin-right: 20px;
}

.itemPhone {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
    margin-bottom: 8px;
}

.itemEmail {
    font-weight: 500;
    font-size: 14px;
    color: #DEDEDE;
}

.linkWrapper {
    border: 2px solid #262B40;
    padding: 64px 39px 76px 56px;
}

.joinUsBtn {
    background: transparent;
    border: none;
}

.joinUsBtn span {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 16px;
    margin-bottom: 10px;
}

.linkTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.linkText {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 46px;
}

@media only screen and (max-width: 600px ) {
    .leadWrapper {
        grid-template-columns: 1fr;
        padding-bottom: 64px;
    }

    .person {
        padding: 24px 20px;
        position: absolute;
        width: 100%;
        left: 0;
    }

    .itemJob {
        margin-bottom: 18px;
    }

    .smWrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .itemName {
        font-size: 22px;
    }

    .linkWrapper {
        margin-top: 300px;
    }
}
