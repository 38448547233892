.thisContainer {
    background: #258968;
    padding: 48px 0;
}

.title {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    padding-bottom: 34px;
}

.cardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.card {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #2FB37F;
    padding: 24px 36px;
    cursor: pointer;
    min-height: 200px;
}

.cardTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
}

.date {
    font-weight: 400;
    font-size: 14px;
    color: #DEDEDE;
}

.joinUsBtn {
    background: transparent;
    border: none;
    margin-top: 20px;
}

.joinUsBtn span {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 16px;
}

@media only screen and (max-width: 600px ) {
    .cardList {
        grid-template-columns: 1fr;
    }
}
