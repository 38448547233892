.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    top: 0;
}

.card {
    width: 80vh;
    height: 80vh;
    background: #2C3743;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.imageWrapper{
     object-fit: cover;
     width: 100%;
     display: block;
     height: 100%;
}

.like {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.closeBtn {
    position: absolute;
    top: 45px;
    right: 45px;
}

.bgPanelClass{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(42, 42, 42, 0.5);
    filter: blur(50);
}


@media only screen and (max-width: 600px ) {
    .card {
        width: 90vw;
        height: 90vw;
    }
    .closeBtn {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
