.dropdown {
}

.dropdown:hover .list{
    display: flex;
}

.selected {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 36px;
    position: relative;
    padding: 5px 0;
}

.list {
    position: absolute;
    display: none;
    flex-direction: column;
    background: #2FB37F;
    padding: 10px;
    border-radius: 5px;
}

.dropdownItem {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    position: relative;
    text-decoration-color: transparent;
    transition: all 300ms linear;
}

.dropdownItem:hover {
    text-decoration: underline;
    text-decoration-color: #fff;
}

.dropdownItem + .dropdownItem {
    margin-top: 10px;
}
