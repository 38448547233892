.redirectBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #F47D63;
    margin-top: 16px;
}

.redirectBtn img {
    margin-left: 10px;
}

.title {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    position: relative;
    padding-top: 76px;
    margin-bottom: 57px;
}

.title:after {
    content: '';
    width: 36px;
    height: 36px;
    background: #F47D63;
    position: absolute;
    margin-top: -15px;
    margin-left: 5px;
}

.subtitle {
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.mainImage {
    margin-bottom: 36px;
}

.mainImage img {
    width: 100%;
}

.onlyWeb {
    display: block;
}

.onlyMobile {
    display: none;
}

.date {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #DEDEDE;
    margin-bottom: 12px;
}

.mainContentTitle {
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 18px;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    margin-bottom: 18px;
}

.contentLeft {

}

.blogItem {
    margin-bottom: 20px;
    display: flex;
}

.blogItemImageWrapper {
    margin-right: 17px;
    max-width: 170px;
    max-height: 170px;
    overflow: hidden;
}

.blogItemImageWrapper img {
    width: 100%;
}

.blogItemRight {

}

.blogItemDate {
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #DEDEDE;
    margin-bottom: 12px;
}

.blogItemTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
}

.blogItemSubtitle {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 10px;
}

.hl {
    height: 1px;
    background: #262B40;
    width: 100%;
    margin: 36px 0;
}

@media only screen and (max-width: 600px ) {
    .onlyWeb {
        display: none;
    }

    .onlyMobile {
        display: block;
    }

    .content {
        grid-template-columns: 1fr;
    }

    .blogItemTitle {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .hl {
        margin: 0;
    }
}
